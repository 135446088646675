import { Heading, SimpleGrid, useColorModeValue, Text, Img, Center, Flex, Divider, HStack, Tooltip, IconButton, Link, Box, LinkBox, LinkOverlay, Skeleton, Spacer } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FaBriefcase, FaCalendar, FaCalendarDay, FaFacebook, FaInfo, FaInfoCircle, FaInstagram, FaLinkedin, FaTwitter, FaUser } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { Card } from '../../components/card/Card'
import { Feature } from '../../components/feature/Feature'
import { PoweredBy } from '../../components/poweredBy/PoweredBy'
import supabase from '../../configs/Supabase'
import upGradLogSq from '../../assets/images/mindmergesq.png'
import moment from 'moment'

export const Verification = () => {
    //@ts-ignore
    const { id } = useParams()
    const [verificationData, setVerificationData] = useState<object[] | null>(null)
    const [certificateDataFromSupabase, setCertificateDataFromSupabase] = useState<object[] | null>(null)
    const textColor = useColorModeValue('gray.600', 'gray.400')
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => {
        const { data, error } = await supabase
                                    .rpc('get_certificate_data_for_verification', { certificate_id: id, certificate_short_id: id })
        const { data:certiData, error:certiError } = await supabase.from('certificates').select(`extra_metadata`).eq('id', id)
        if (error || certiError) {
            setIsLoading(false);
            return
        } else {
            setVerificationData(data)
            setCertificateDataFromSupabase(certiData)
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData()
    }, [supabase])

    if ( verificationData && verificationData.length > 0 && certificateDataFromSupabase && certificateDataFromSupabase.length > 0 ) {
        const certificateData = verificationData[0] as {
            id: string,
            certificate_short_code: string,
            name: string,
            type: string,
            organization_name: string,
            website: string,
            linkedin: string,
            organization_type: string,
            twitter: string,
            facebook: string,
            instagram: string,
            about_organization: string,
            logo_url: string,
            event_name: string,
            event_type: string,
            start_date: Date,
            end_date: Date

        }
        return (
            <Center>
                <Card w={['90%', null, '80%', '80%']}>
                    <LinkBox>
                        <Flex alignItems="center">
                            <Img
                                htmlWidth="50px"
                                htmlHeight="50px"
                                height='50px'
                                mr={3}
                                objectFit="cover"
                                // src={certificateData.logo_url || `https://ui-avatars.com/api/?name=${certificateData.organization_name}`}
                                src={upGradLogSq}
                                alt="Organization Logo"
                            />
                            <LinkOverlay href={certificateData.website || '/#'}>
                                <Heading size="lg">
                                    {certificateData.organization_name}
                                </Heading>
                            </LinkOverlay>
                            <Spacer />
                            <Img
                                htmlWidth="80px"
                                htmlHeight="80px"
                                height='80px'
                                mr={3}
                                objectFit="cover"
                                src="https://gpnmjenofbfeawopmhkj.supabase.co/storage/v1/object/public/public/gmc_files/check-unscreen.gif"
                                alt="Organization Logo"
                            />
                        </Flex>
                    </LinkBox>
                    {/* <Text mt="2">
                        {`A ${certificateData.organization_type} Organization`}
                    </Text> */}
                    <Divider my={5} />
                    <Flex justifyContent='space-between' direction={['column', 'column', 'column', 'row']} >
                        <Img
                            m={5}
                            maxH='350'
                            maxW='500'
                            objectFit="cover"
                            // src={`https://us-central1-gmc-testing.cloudfunctions.net/certificateImage/v1/certificate/${window.location.href.split('/').at(-1)}?size=small`}
                            src={`https://us-central1-gmc-testing.cloudfunctions.net/certificateImage/v1/certificate/${id}?size=small`}
                            // src='https://us-central1-gmc-testing.cloudfunctions.net/certificateImage/v1/certificate/c3c5f8f8-916a-4e1e-bbed-3cf678091457?size=small'
                            alt="Certificate Thumbnail"
                        />
                        {/* <Img
                            htmlWidth="80px"
                            htmlHeight="80px"
                            height='80px'
                            position='absolute'
                            top='450'
                            left='240'
                            mr={3}
                            objectFit="cover"
                            src="https://gpnmjenofbfeawopmhkj.supabase.co/storage/v1/object/public/public/gmc_files/tick.gif"
                            alt="Organization Logo"
                        /> */}
                        <Box px={[0, null , 20, 20 ]} m={5}>
                            <SimpleGrid columns={{ base: 1, md: 1 }} spacing="10" mb="10">
                                <Feature icon={FaUser} title="Name">
                                    {certificateData.name}
                                </Feature>
                                <Feature icon={FaInfo} title={ (certificateData.type === 'Offer letter')? "Document Category" : "Certificate Type"}>
                                    {certificateData.type}
                                </Feature>
                                <Feature icon={FaCalendar} title={ (certificateData.type === 'Offer letter')? "Document Name" : "Event Name"}>
                                    {certificateData.event_name}
                                </Feature>
                                <Feature icon={FaInfoCircle} title={ (certificateData.type === 'Offer letter')? "Document type" : "Event type"}>
                                    {certificateData.event_type}
                                </Feature>
                            </SimpleGrid>
                        </Box>
                    </Flex>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing="10" mb="10" pl='5'>
                        <Feature icon={FaCalendarDay} title="Issue Date">
                            {moment(certificateData.start_date).local().format('MM/DD/YYYY')}
                        </Feature>
                        <Feature icon={FaCalendarDay} title="Expiry Date">
                            {moment(certificateData.end_date).local().format('MM/DD/YYYY')}
                        </Feature>
                    </SimpleGrid>
                    <Feature icon={FaBriefcase} title="About Organization">
                        {certificateData.about_organization}
                    </Feature>
                    <Divider mt={5} mb={2} />
                    {
                        ( certificateData.facebook || certificateData.linkedin || certificateData.instagram || certificateData.twitter ) &&
                        <Flex justifyContent="center" mb="3">
                            <Text color={textColor}>
                                Follow us at
                            </Text>
                        </Flex>
                    }
                    <Flex justifyContent="center">
                        <HStack>
                            {
                                certificateData.facebook &&
                                <Tooltip label="Follow on facebook" aria-label="Follow on facebook">
                                    <IconButton 
                                        aria-label="Follow on facebook"
                                        icon={<FaFacebook />}
                                        colorScheme="facebook"
                                        onClick={() => window.open(certificateData.facebook, "_blank")}
                                    />
                                </Tooltip>
                            }
                            {
                                certificateData.twitter &&
                                <Tooltip label="Follow on Twitter" aria-label="Follow on Twitter">
                                    <IconButton 
                                        aria-label="Follow on Twitter"
                                        icon={<FaTwitter />}
                                        colorScheme="twitter"
                                        onClick={() => window.open(certificateData.twitter, "_blank")}
                                    />
                                </Tooltip>
                            }
                            {
                                certificateData.linkedin &&
                                <Tooltip label="Follow on Linked In" aria-label="Follow on Linked In">
                                    <IconButton 
                                        aria-label="Follow on Linked In"
                                        icon={<FaLinkedin />}
                                        colorScheme="linkedin"
                                        onClick={() => window.open(certificateData.linkedin, "_blank")}
                                    />
                                </Tooltip>
                            }
                            {
                                certificateData.instagram &&
                                <Tooltip label="Follow on Instagram" aria-label="Follow on Instagram">
                                    <IconButton 
                                        aria-label="Follow on Instagram"
                                        icon={<FaInstagram />}
                                        colorScheme="orange"
                                        onClick={() => window.open(certificateData.instagram, "_blank")}
                                    />
                                </Tooltip>
                            }
                        </HStack>
                    </Flex>
                    <PoweredBy />
                </Card>
            </Center>
        )
    }

    if (isLoading) {
		return (
            <Center>
                <Skeleton height="100px" />
            </Center>
        )
	}

    return (
        <Center>
            <Card w={['90%', null, '60%', '60%']}>
                <Center w="100%" h="100%" mb="5">
                    <Img
                        htmlWidth="150px"
                        htmlHeight="150px"
                        height='150px'
                        objectFit="cover"
                        src="https://thumbs.gfycat.com/SpecificSlimIndianringneckparakeet-small.gif"
                        alt="Certificate Not found"
                    />
                </Center>
                <Divider my={5} />
                <Box textAlign="center">
                    <Heading size="lg" mb="5">
                        Certificate Not Found
                    </Heading>
                    <Text>
                        We are unable to find any certificate associated with the provided certificate id. If you 
                        think its a mistake contact us at {" "}
                        <Link 
                            href="mailto:support@givemycertificate.com"
                        >
                            support@givemycertificate.com
                        </Link>
                    </Text>
                </Box>
                <PoweredBy />
            </Card>
        </Center>
    )
}
