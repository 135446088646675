import { Flex, HTMLChakraProps, Image, Text, useToken } from '@chakra-ui/react'
import * as React from 'react'
import GMCLogSq from '../../assets/images/mindmergesq.png'

export const Logo = (props: HTMLChakraProps<'div'> & { iconColor?: string }) => {
  const { iconColor = 'currentColor', ...rest } = props
  const color = useToken('colors', iconColor)
  return (
    <Flex
      alignItems="center"
      {...rest}
    >
      <Image
        src={GMCLogSq} 
        h="inherit"
        mr={2}
        color={color}
      />
      {/* <Text
        fontSize="28px"
        color="#ed2b36"
        fontWeight="700"
      >
        Mind Merge
      </Text> */}
    </Flex>
  )
}
